import React from "react"
import { Link } from "gatsby"

const footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row footer-row">
          <div className="col-12 d-md-none">
            <h2>+1&nbsp; (323) 914–3118</h2>
            <h2>
              E:
              <a href="mailto:hola@rg-rq.com?subject=hola" target="_blank" rel="noreferrer">
                &nbsp; hola@rg-rq.com
              </a>
            </h2>
          </div>
          <div className="col-12 col-lg-3">
            <h2>
              <a
                href="https://www.instagram.com/rgrq.xyz/"
                rel="noreferrer"
                target="_blank"
              >
                Instagram ↗
              </a>
            </h2>
          </div>
          <div className="offset-md-2 col-md-3 d-none d-md-block">
            <h2 className="url">
              <Link to="/">rg-rq.com</Link>
            </h2>
          </div>
          <div className="col-12 d-md-none">
            <h2 className="url">
              <Link to="/">Work ↗</Link>
            </h2>
          </div>
          <div className="col-12 d-md-none">
            <h2 className="url">
              <Link to="/memories">Memories ↗</Link>
            </h2>
          </div>
          <div className="col-12 offset-md-2 col-md-2 d-none d-md-block">
            <h2>↓ Los Angeles</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
export default footer
