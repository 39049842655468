import React, { useRef, useEffect } from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"

const Memories = () => {
  const zero = useRef(null)
  const one = useRef(null)
  const two = useRef(null)
  const three = useRef(null)
  const four = useRef(null)
  const five = useRef(null)
  const six = useRef(null)
  const seven = useRef(null)
  const eight = useRef(null)
  const nine = useRef(null)
  const ten = useRef(null)
  const eleven = useRef(null)
  const twelve = useRef(null)
  const thirteen = useRef(null)

  useEffect(() => {
    var Draggabilly = require("draggabilly")
    new Draggabilly(zero.current)
    new Draggabilly(one.current)
    new Draggabilly(two.current)
    new Draggabilly(three.current)
    new Draggabilly(four.current)
    new Draggabilly(five.current)
    new Draggabilly(six.current)
    new Draggabilly(seven.current)
    new Draggabilly(eight.current)
    new Draggabilly(nine.current)
    new Draggabilly(ten.current)
    new Draggabilly(eleven.current)
    new Draggabilly(twelve.current)
    new Draggabilly(thirteen.current)
  }, [])
  console.log({ one })
  return (
    <Layout>
      <Header />
      <div className="memories">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>09. Memories</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>Now and Then</h2>
            </div>
            <div className="col-lg-4">
              <h2>Too much to write in here</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p>Click and drag any image</p>
              <img
                ref={zero}
                className="img-fluid media zero"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126807/rgrq/static/memories/00_rsxlfb.png"
                alt=""
              />
              <div
                ref={one}
                className="embed-responsive embed-responsive-16by9 media one"
              >
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="https://res.cloudinary.com/rwh/video/upload/v1607126809/rgrq/static/memories/01_qyivk0.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                ref={two}
                className="embed-responsive embed-responsive-16by9 media two"
              >
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="https://res.cloudinary.com/rwh/video/upload/v1607126808/rgrq/static/memories/02_jnmobz.mp4" type="video/mp4" />
                </video>
              </div>
              <div
                ref={three}
                className="embed-responsive embed-responsive-16by9 media three"
              >
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="https://res.cloudinary.com/rwh/video/upload/v1607126812/rgrq/static/memories/03_dkxk9w.mp4" type="video/mp4" />
                </video>
              </div>
              <img
                ref={four}
                className="img-fluid media four"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126808/rgrq/static/memories/04_hniyvs.png"
                alt=""
              />
              <img
                ref={five}
                className="img-fluid media five"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126808/rgrq/static/memories/05_yd9ieo.jpg"
                alt=""
              />
              <img
                ref={six}
                className="img-fluid media six"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126809/rgrq/static/memories/06_mxmktk.png"
                alt=""
              />
              <img
                ref={seven}
                className="img-fluid media seven"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126809/rgrq/static/memories/07_rr8kq7.jpg"
                alt=""
              />
              <img
                ref={eight}
                className="img-fluid media eight"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126810/rgrq/static/memories/08_akepeb.jpg"
                alt=""
              />
              <img
                ref={nine}
                className="img-fluid media nine"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126810/rgrq/static/memories/09_qp7wnw.png"
                alt=""
              />
              <img
                ref={ten}
                className="img-fluid media ten"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126810/rgrq/static/memories/010_jursar.png"
                alt=""
              />
              <img
                ref={eleven}
                className="img-fluid media eleven"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126811/rgrq/static/memories/011_zxeexq.png"
                alt=""
              />
              <img
                ref={twelve}
                className="img-fluid media tweleve"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126812/rgrq/static/memories/012_vppv0x.png"
                alt=""
              />
              <img
                ref={thirteen}
                className="img-fluid media thirteen"
                src="https://res.cloudinary.com/rwh/image/upload/v1607126811/rgrq/static/memories/013_rxpri5.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
export default Memories
